.generation-result-wrapper {
  width: 100%;
}

.generation-result {
  padding: 9px;
  min-height: 40px;
}
.generation-result.complete {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom: 0px;
}
.generation-result .generation-result-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  max-height: calc(50vh - 150px);
  overflow-y: scroll;
  color: #1b2559;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
}
.generation-result .generation-result-hint {
  padding: 0;
  padding-top: 12px;
}

.generation-result-hint {
  padding: 12px 0;
  font-size: 12px;
  user-select: none;
  --tw-text-opacity: 1;
  color: rgb(115 122 135/var(--tw-text-opacity));
}
.generation-result-hint .prompt-message {
  width: fit-content;
  max-width: 100%;
  display: flex;
  align-items: start;
  color: rgb(199 204 214/var(--tw-text-opacity));
}

.generation-toolbar {
  border-radius: 16px;
  background: linear-gradient(360deg, #fff 0%, #f6fbff 100%);
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.08);
  border: 1px solid var(--pill-color-stroke, #e2e8f0);
  padding: 12px;
  padding-top: 0px;
}
.generation-toolbar.top-cover {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.generation-toolbar .menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
}
.generation-toolbar .btn-toolbar {
  display: flex;
  min-width: 51px;
  height: 36px;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--pill-color-stroke, #e2e8f0);
  background: #fff !important;
  gap: 6px;
  cursor: pointer;
  color: #1b2559;
  font-size: 14px;
  font-style: normal;
  line-height: 16px;
}
.generation-toolbar .btn-toolbar .hkgai-icon {
  width: 16px;
  height: 16px;
}
.generation-toolbar .prompt-message span {
  font-size: 12px;
  color: rgb(199, 204, 214);
}
.generation-toolbar .btn-done {
  display: flex;
  height: 36px;
  padding: 0px 19px 0px 11px;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  background: #165dff;
  cursor: pointer;
  color: #f7f9fb;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}
.generation-toolbar .btn-done .hkgai-icon {
  width: 20px;
  height: 20px;
}

.reportDdl {
  display: flex;
  width: 420px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: var(--4, 4px);
  border: 1px solid var(--border-2, #e5e6eb);
  background: var(--bg-2, #fff);
  padding: 10px;
  z-index: 1000;
}
.reportDdl .reportHeader {
  display: flex;
  padding: 4px 0px 0;
  align-items: center;
  gap: var(--8, 8px);
  color: rgb(153, 153, 153);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.reportDdl .arco-icon-close {
  color: rgb(78, 89, 105);
  font-size: 18px;
}
.reportDdl .textarea {
  margin: 4px 0 0;
}
.reportDdl .textarea textarea {
  height: 100px;
  background: var(--bg-2, #fff);
  border-radius: 2px;
  border: 1px solid #e5e6eb;
  caret-color: #165dff;
}
.reportDdl .textarea textarea::-webkit-resizer {
  display: none;
}
.reportDdl .btnBox {
  text-align: right;
  margin: 8px 0 0;
}

.btn_report {
  display: flex;
  height: 36px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  border: 1px solid #165dff;
  font-size: 14px;
  color: #165dff;
}
.btn_report .hkgai-icon {
  font-size: 14px;
}