@charset "UTF-8";
.writing-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden auto;
  margin: 24px;
  margin-bottom: 0;
}
.writing-body .title-input-wrap {
  height: 32px;
  margin: 16px 0px;
  display: flex;
  font-size: 24px;
}
.writing-body .title-input-wrap input {
  background: none;
  border: 0px;
  caret-color: #165dff;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  color: #191d28;
}
.writing-body .title-input-wrap input::placeholder {
  color: rgba(25, 29, 40, 0.2);
}
.writing-body .editor-wrap {
  width: 800px;
  margin: 0 auto;
  position: relative;
  padding-bottom: 300px;
  margin: 0 auto;
}
.writing-body .editor-wrap.small-size {
  width: 600px;
}
.writing-body .editor-wrap.hidden {
  display: none !important;
}
.writing-body .editor-wrap.paddingBottom {
  padding-bottom: 10px !important;
}
.writing-body .input-wrap {
  width: 100%;
  border-radius: 16px;
  background: var(--white-100, #fff);
  padding: 0px;
}
.writing-body .prompt-wrap {
  width: 100%;
}
.writing-body .prompt-wrap .title-info {
  width: 812px;
  margin-top: 20px;
  margin-bottom: 16px;
  color: #718096;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.writing-body .prompt-wrap .title-info .hkgai-icon {
  width: 20px;
  height: 20px;
  color: #1b2559;
  margin-left: 12px;
  cursor: pointer;
}
.writing-body .prompt-wrap .prompt-list {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}
.writing-body .prompt-wrap .prompt-list .prompt-list_item {
  display: inline-flex;
  height: 44px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 45px;
  border: 1px solid #e2e8f0;
  background: #fff;
  margin-bottom: 12px;
  color: #191d28;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  cursor: pointer;
}
.writing-body .prompt-wrap .prompt-list .hkgai-icon {
  width: 24px;
  height: 24px;
}
.writing-body .editor-wrapper {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  height: calc(100vh - 235px);
  width: 100%;
  overflow: hidden;
}

.editor-element {
  --tw-text-opacity: 1;
  color: rgb(12 13 14/var(--tw-text-opacity));
  font-size: 16px;
  letter-spacing: 0.045px;
  line-height: 28px;
  white-space: pre-wrap;
}

.generate-wrap {
  width: 100%;
  margin: 0px auto;
  position: absolute;
  display: none;
  transition: all 500ms;
  height: fit-content;
}

.btn-ask-ai {
  display: inline-flex;
  height: 44px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--pill-color-stroke, #e2e8f0) !important;
  background: linear-gradient(97.03deg, #0043ff 7.42%, #00a2ff 100%), linear-gradient(135.59deg, #80e4fa -0.76%, #40bbff 96.12%) !important;
}
.btn-ask-ai .hkgai-icon {
  width: 16px;
  height: 16px;
}
.btn-ask-ai span {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin-left: 0px !important;
  color: #ffffff;
}
.btn-ask-ai:hover, .btn-ask-ai.clicked {
  background: linear-gradient(97.03deg, #003ce6 7.42%, #0092e6 100%), linear-gradient(135.59deg, #73cdb9 -0.76%, #3ab2e6 96.12%);
}
.btn-ask-ai:hover .hkgai-icon,
.btn-ask-ai:hover span, .btn-ask-ai.clicked .hkgai-icon,
.btn-ask-ai.clicked span {
  color: #e6e6e6;
}

.ai-input-box {
  width: 100%;
  max-width: 800px;
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.0509803922);
  background: #fff;
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.0784313725);
  padding: 12px;
}
.ai-input-box .input-item {
  margin-bottom: 12px;
}
.ai-input-box .input-item .label {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  display: flex;
  align-items: center;
  gap: 8px;
}
.ai-input-box .input-item .label .sence-tag {
  background: var(--border-1, #f2f3f5);
  border: 1px solid var(--fill-3, #e5e6eb);
  padding: 3px 8px 3px 8px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}
.ai-input-box .input-item:last-of-type {
  margin-bottom: 0;
}
.ai-input-box textarea {
  width: 766px;
  resize: none;
  padding: 8px 16px;
  min-height: 36px;
  max-height: calc(50vh - 150px);
  border-radius: 8px;
  background: inherit;
  border: 1px solid #165dff;
  caret-color: #165dff;
  font-size: 14px;
  line-height: 18px;
  box-sizing: border-box;
}
.ai-input-box textarea::placeholder {
  color: rgba(28, 28, 28, 0.2);
}
.ai-input-box textarea::-webkit-scrollbar-track {
  background: var(--Select-menu, #f1f5f8);
}
.ai-input-box textarea:hover {
  background: inherit;
  border: 1px solid #165dff;
}
.ai-input-box textarea.suffix {
  padding-right: 90px;
}
.ai-input-box .operate-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.count_drop_list {
  width: 136px;
  max-height: none;
  padding: var(--8, 8px);
  border-radius: var(--16, 16px);
  border: 1px solid var(--black-10, rgba(28, 28, 28, 0.1));
}
.count_drop_list .arco-dropdown-menu-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: linear-gradient(0deg, var(--white-20, rgba(255, 255, 255, 0.2)) 0%, var(--white-20, rgba(255, 255, 255, 0.2)) 100%), var(--white-80, rgba(255, 255, 255, 0.8));
  backdrop-filter: blur(20px);
  gap: 8px;
}
.count_drop_list .arco-dropdown-menu-item {
  display: flex;
  padding: var(--8, 8px);
  align-items: flex-start;
  align-content: center;
  gap: 16px var(--16, 16px);
  align-self: stretch;
  flex-wrap: wrap;
  height: 36px;
  border-radius: var(--8, 8px);
}
.count_drop_list .arco-dropdown-menu-item .icon_box {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.count_drop_list .arco-dropdown-menu-item .icon_box .hkgai-icon {
  width: 20px;
  height: 20px;
  color: #4e5969;
}
.count_drop_list .arco-dropdown-menu-item .text {
  color: var(--black-100, #1c1c1c);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.count_drop_list .arco-dropdown-menu-item.current {
  background: #f1f2f3;
}

.attachment_drop_list {
  display: flex;
  width: 124px;
  padding: var(--4, 4px) 0px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: var(--4, 4px);
  border: 1px solid var(--border-2, #e5e6eb);
  background: var(--bg-5, #fff);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.attachment_drop_list .arco-dropdown-menu-item,
.attachment_drop_list .uploadMenuItem {
  width: 124px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.attachment_drop_list .arco-dropdown-menu-item .hkgai-icon,
.attachment_drop_list .uploadMenuItem .hkgai-icon {
  width: 20px;
  height: 20px;
  color: #1b2559;
}

.addLinkModal .arco-modal-header,
.addLinkModal .arco-modal-footer,
.addLinkModal .arco-input {
  border: 0px;
}
.addLinkModal .arco-modal-content {
  padding-top: 0px;
  padding-bottom: 0px;
}
.addLinkModal .linkBody {
  display: flex;
  min-height: var(--80, 80px);
  padding: 0 var(--16, 16px);
  align-items: flex-start;
  border-radius: var(--8, 8px);
  border: 1px solid #e5e6eb;
  gap: 12px;
}
.addLinkModal .linkBody.error {
  border-color: #f00;
}
.addLinkModal .linkBody .hkgai-icon {
  width: 20px;
  height: 20px;
  color: #cccccc;
}
.addLinkModal .linkBody .hkgai-icon-Paperclip {
  margin-top: 16px;
}
.addLinkModal .linkBody .arco-input-tag {
  background: none;
  margin-top: 10px;
  padding: 0px;
}
.addLinkModal .linkBody .arco-input-tag-focus {
  border: 1px solid transparent;
  background: inherit;
  outline: none;
}
.addLinkModal .linkBody .arco-input-tag::-webkit-resizer {
  display: none;
}
.addLinkModal .linkBody .arco-input-tag:hover {
  background: none;
}
.addLinkModal .linkBody .arco-input-tag-tag {
  max-width: 386px;
}
.addLinkModal .linkBody .arco-input-tag-input {
  padding-left: 0;
}
.addLinkModal .linkBody .errorMessage {
  position: absolute;
  bottom: -26px;
  left: 20px;
  color: #f00;
  font-size: 14px;
  font-weight: 400;
}

.writing-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  overflow-x: auto;
  height: 100%;
}
.writing-wrap .writing-main,
.writing-wrap .sidebar-content {
  background: rgb(255, 255, 255);
  height: calc(100vh - 64px);
}
.writing-wrap .writing-main {
  caret-color: #165dff;
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: 0 var(--8, 8px) 0 0;
  position: relative;
  height: 100%;
  width: 100%;
}
.writing-wrap .sidebar-content {
  display: flex;
  flex-direction: row;
  border-radius: var(--8, 8px) 0 0 0;
}

::selection {
  background: rgba(22, 93, 255, 0.3);
  padding: 0.125rem 0;
}

.writing-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0 0 0;
  gap: 16px;
  color: rgb(34, 34, 34);
  font-size: 12px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 11;
}
.writing-actions .word-count,
.writing-actions .download-btn,
.writing-actions .clear-btn {
  display: flex;
  align-items: center;
  gap: 4px;
}
.writing-actions .icon-button {
  cursor: pointer;
}
.writing-actions .icon-button-dis {
  cursor: not-allowed;
  color: rgb(204, 204, 204);
  pointer-events: none;
  user-select: none;
}
.writing-actions .icon-button-dis .hkgai-icon-clear {
  cursor: not-allowed;
  opacity: 0.2;
}

.word-count {
  display: flex;
  align-items: center;
  gap: 4px;
  color: rgb(204, 204, 204);
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
}

.feedback-drawer {
  background-color: rgb(239, 247, 255);
}

::-webkit-scrollbar {
  display: none;
  height: 6px;
  width: 6px; /* 设置滚动条的宽度 */
}

::-webkit-scrollbar-thumb {
  background-color: #d6dbea; /* 设置滚动条滑块的颜色 */
  border-radius: 9999px; /* 设置滚动条滑块的圆角 */
}

.arco-message-success {
  background-color: #e8ffea !important;
}

.input-box-send-button {
  line-height: 36px;
  height: 36px;
  border-radius: 8px;
}