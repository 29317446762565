.loader-wrap {
  padding: 16px;
}
.loader-wrap .loader {
  width: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}

@keyframes l5 {
  0% {
    box-shadow: 14px 0 #000, -14px 0 rgba(0, 0, 0, 0.1333333333);
    background: #000;
  }
  33% {
    box-shadow: 14px 0 #000, -14px 0 rgba(0, 0, 0, 0.1333333333);
    background: rgba(0, 0, 0, 0.1333333333);
  }
  66% {
    box-shadow: 14px 0 rgba(0, 0, 0, 0.1333333333), -14px 0 #000;
    background: rgba(0, 0, 0, 0.1333333333);
  }
  100% {
    box-shadow: 14px 0 rgba(0, 0, 0, 0.1333333333), -14px 0 #000;
    background: #000;
  }
}