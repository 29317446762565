.generate-feedback-modal {
  background-color: #fff;
  width: 464px;
  padding-top: 40px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
}
.generate-feedback-modal .arco-modal-content {
  display: flex;
  flex-direction: column;
  padding: 0;
}
.generate-feedback-modal .generate-title {
  font-size: 16px;
  font-weight: bold;
}
.generate-feedback-modal .generate-form .arco-form-item {
  flex-direction: column;
  display: flex;
  margin-top: 12px;
  margin-bottom: 0;
}
.generate-feedback-modal .generate-form .arco-form-item .arco-col-19 {
  width: 100%;
}
.generate-feedback-modal .generate-form .arco-form-label-item {
  line-height: 16px;
  width: auto;
}
.generate-feedback-modal .generate-form .arco-form-label-item > label {
  flex-direction: row-reverse;
  display: flex;
  white-space: nowrap;
  font-size: 12px;
  width: 400;
}
.generate-feedback-modal .generate-form .arco-form-label-item .arco-form-item-symbol {
  display: flex;
  align-items: center;
}
.generate-feedback-modal .generate-form .form-item-rate {
  height: 24px;
}
.generate-feedback-modal .generate-form .arco-textarea {
  width: 100%;
  resize: none;
  padding: 12px;
  height: 226px;
  border-radius: 4px;
  background: inherit;
  border: 1px solid #e5e6eb;
  font-size: 14px;
}
.generate-feedback-modal .generate-button {
  margin-top: 24px;
  width: 84px;
  height: 32px;
  align-self: center;
}