.menu-toolbar-wrap {
  width: 100%;
  border-radius: 16px;
  background: linear-gradient(360deg, #fff 0%, #f6fbff 100%);
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.08);
  border: 1px solid var(--pill-color-stroke, #e2e8f0);
  padding: 12px;
  position: absolute;
  top: -10000px;
  opacity: 0;
}
.menu-toolbar-wrap.small-size {
  width: 600px;
}
.menu-toolbar-wrap .menu {
  display: flex;
  align-items: center;
  width: 100%;
}
.menu-toolbar-wrap .btn-toolbar {
  display: flex;
  min-width: 20px;
  height: 36px;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  border: 1px solid var(--pill-color-stroke, #e2e8f0);
  background: #fff;
  color: #222;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  line-height: 16px;
  cursor: pointer;
}
.menu-toolbar-wrap .btn-toolbar .hkgai-icon {
  width: 20px;
  height: 20px;
}
.menu-toolbar-wrap .btn-toolbar.selected {
  background: rgba(22, 93, 255, 0.1) !important;
  border-color: rgba(22, 93, 255, 0.1) !important;
  color: var(--Color, #165dff) !important;
}
.menu-toolbar-wrap .rich-icon-box {
  display: flex;
  height: 36px;
  border-radius: 4px;
  border: 1px solid var(--pill-color-stroke, #e2e8f0);
  background: #fff;
  color: #1b2559;
  padding: 0px 12px;
}
.menu-toolbar-wrap .rich-icon-box .arco-menu-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 0px;
}
.menu-toolbar-wrap .rich-icon-box .rich-icon-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin: unset;
  padding: unset;
  line-height: unset;
}
.menu-toolbar-wrap .rich-icon-box .rich-icon-item:hover {
  background: #e8efff !important;
  color: rgb(var(--arcoblue-6));
}
.menu-toolbar-wrap .rich-icon-box .hkgai-icon {
  width: 20px;
  height: 20px;
}
.menu-toolbar-wrap .count-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;
  padding-left: 14px;
  padding-right: 8px;
  padding-bottom: 2px;
  padding-top: 2px;
  border-radius: 4px;
  border: 1px solid var(--pill-color-stroke, #e2e8f0);
  background: rgba(22, 93, 255, 0.1019607843);
  color: #1b2559;
  -webkit-text-fill-color: #1b2559;
  cursor: pointer;
  gap: 6px;
  font-size: 14px;
}
.menu-toolbar-wrap .count-btn:hover {
  border: 1px solid var(--pill-color-stroke, #e2e8f0);
  background: rgba(22, 93, 255, 0.1019607843);
  color: #1b2559;
}
.menu-toolbar-wrap .count-btn .hkgai-icon {
  width: 20px;
  height: 20px;
  margin: 0px;
  color: rgba(27, 37, 89, 0.2);
}
.menu-toolbar-wrap .count-btn.arco-dropdown-popup-visible .hkgai-icon {
  transform: rotate(90deg);
}
.menu-toolbar-wrap .count-btn.hasValue {
  background: rgba(22, 93, 255, 0.1) !important;
  border-color: rgba(22, 93, 255, 0.1) !important;
  color: var(--Color, #165dff) !important;
}
.menu-toolbar-wrap .count-btn.hasValue .hkgai-icon {
  color: var(--Color, #165dff) !important;
}
.menu-toolbar-wrap .menu-small {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.menu-toolbar-wrap .menu-small .rich-icon-box {
  border: 0px;
  padding: 0px;
  width: 150px;
  background: none;
}
.menu-toolbar-wrap .menu-small .rich-icon-box .rich-icon-item {
  background: none;
}
.menu-toolbar-wrap .menu-small .info-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.menu-toolbar-wrap .menu-small .info-box .btn-toolbar {
  border: 0px;
  background: none;
  padding: 0px 10px;
}
.menu-toolbar-wrap .menu-small-2 {
  display: flex;
  align-items: center;
  gap: 10px;
}
.menu-toolbar-wrap .menu-small-2 .btn-toolbar {
  padding: 0px 28px !important;
  flex: 1;
  flex-basis: 16.6666667%;
}
.menu-toolbar-wrap .menu-small-2.english-mode .btn-toolbar {
  padding: 0px 8px !important;
  font-size: 12px;
}
.menu-toolbar-wrap .menu-small-2.english-mode .count-btn {
  font-size: 12px;
  padding-left: 8px;
  padding-right: 4px;
  gap: 0px;
}
.menu-toolbar-wrap .prompt-input {
  border-radius: 8px;
  background: none;
  height: 36px;
  flex: 1;
}
.menu-toolbar-wrap .prompt-input .arco-input-inner-wrapper {
  padding-left: 6px;
  border-radius: 8px;
  border-color: #e5e5e5;
}
.menu-toolbar-wrap .btn_confirm {
  width: 73px;
  height: 36px;
  padding: 0px var(--12, 12px);
}
.menu-toolbar-wrap .btn_confirm.active {
  color: #1b2559;
}