.attachment-toolbar {
  gap: 10px;
  display: flex;
  align-items: center;
  height: 100%;
}
.attachment-toolbar .arco-btn.ai-button {
  line-height: 36px;
  height: 36px;
  width: fit-content;
  border-radius: 8px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(226, 232, 240);
  display: flex;
  align-items: center;
}
.attachment-toolbar .arco-btn.ai-button .hkgai-icon {
  width: 20px;
  height: 20px;
  color: #1b2559;
  margin-right: 4px;
  display: inline;
}
.attachment-toolbar .arco-btn.ai-button .hkgai-icon + span {
  margin-left: 0;
}
.attachment-toolbar .arco-btn.ai-button:hover {
  background: rgb(255, 255, 255) !important;
  border: 1px solid rgb(226, 232, 240) !important;
}