.btn_report {
  display: flex;
  height: 36px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  font-size: 14px;
  color: #165dff;
  background-color: rgba(22, 93, 255, 0.1490196078);
}
.btn_report .hkgai-icon {
  font-size: 14px;
}