.editor-container {
  width: calc(100% + 20px);
  padding: 10px 10px 0;
  margin-left: -10px;
  margin-top: -10px;
  height: 100%;
  overflow-y: auto;
}
.editor-container .doc-line {
  font-size: 16px;
  color: #222;
  line-height: 1.5;
  margin: 0px;
}
.editor-container .table-block-cell .doc-line {
  margin: 8px 0;
}
.editor-container .table-block-scroll {
  padding-bottom: 15px;
}
.editor-container h1.doc-heading {
  font-size: 26px;
}
.editor-container h2.doc-heading {
  font-size: 22px;
}
.editor-container h3.doc-heading {
  font-size: 20px;
}
.editor-container h4.doc-heading {
  font-size: 18px;
}
.editor-container h5.doc-heading {
  font-size: 16px;
}