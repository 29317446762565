.ai-input-toolbar {
  padding-right: 16px;
  display: flex;
  align-items: center;
}
.ai-input-toolbar .ai-select .arco-select-view {
  line-height: 36px;
  min-width: 92px;
  height: 36px;
  border-radius: 8px;
  padding: 0 24px 0 16px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(226, 232, 240);
}
.ai-input-toolbar .ai-select .arco-select-view-value {
  color: rgb(27, 37, 89);
  font-size: 14px;
  font-weight: 400;
}

.word-num-dropdown-menu .arco-select-popup {
  padding: 4px;
  border-radius: 8px;
  border: 1px solid var(--border-2, rgb(229, 230, 235));
}

.input-word-num,
.input-word-num:hover {
  width: 104px;
  height: 30px;
  margin: 3px 10px 0;
  padding: 4px 8px;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid var(--Color, rgb(22, 93, 255));
  background: rgb(255, 255, 255);
  caret-color: var(--Color, rgb(22, 93, 255));
}
.input-word-num:placeholder,
.input-word-num:hover:placeholder {
  font-size: 14px;
  font-weight: 400;
  color: rgb(229, 229, 229);
}

.select-trigger-popup .arco-select-popup {
  min-width: 124px;
}