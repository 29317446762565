.generating-wrapper {
  width: 100%;
  height: 58px;
  border-radius: 8px;
  background-image: linear-gradient(90deg, #5d51fe 1%, #a049f7 30.31%, #cb4da4 66.24%, #ff8080 97.8%);
  padding: 2px;
  box-sizing: border-box;
  margin: 0px auto;
}
.generating-wrapper .content {
  background: linear-gradient(89.99deg, #e3ecff 0.01%, #ffffff 100%);
  animation: animate_box 5s linear infinite;
  background-size: 400%;
  border-radius: 6px;
  box-sizing: border-box;
  outline: none !important;
  padding: 9px 16px 9px 18px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.generating-wrapper .content .rounded {
  background-color: #f5f7f9 !important;
}
.generating-wrapper .content .arco-btn-text {
  color: #696e7c;
  font-size: 12px;
  padding-right: 0px;
}
.generating-wrapper .content .arco-btn-text:hover {
  color: #3a3d44;
  background-color: #fff !important;
}

@keyframes animate_box {
  0%, 100% {
    background-position: 0%, 50%;
  }
  50% {
    background-position: 100%, 50%;
  }
}
@keyframes shake {
  0% {
    transform: translateX(0);
  }
  6.5% {
    transform: translateX(-5px);
  }
  18.5% {
    transform: translateX(5px);
  }
  31.5% {
    transform: translateX(-5px);
  }
  43.5% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0);
  }
}
.shake-trigger {
  animation: shake 0.5s ease 1;
}

@keyframes fade-out {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}